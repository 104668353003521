import React from 'react';
import { SanityImageObject } from '../sanity/types';
import { getSanityImageURL } from '../sanity/utils';
import { Text } from '../components/Text';
import { css } from '@emotion/react';
import Link from 'next/link';
import tagNameByValue from './card/TagNameByValue';
import Icon from 'src/lib/components/icon/Icon';
import HoverTooltip from './HoverTooltip';

type CardTileProps = {
  image?: SanityImageObject;
  type?: string;
  title?: string;
  opApproved: boolean;
  sustainable: boolean;
  slug?: string;
  location?: string;
};

const CardTile = ({
  image,
  type,
  title,
  slug,
  location,
  opApproved,
  sustainable,
}: CardTileProps) => {
  return (
    <Link href={(slug && `cards/${slug}`) || `/404`}>
      <div
        css={css`
          cursor: pointer;
        `}
      >
        <img
          css={css`
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
          `}
          src={image ? getSanityImageURL(image, 400, 400) : 'placeholder-image.png'}
        />
        <div
          css={css`
            display: flex;
          `}
        >
          <Text
            variant="Caption"
            css={css`
              width: calc(100% - 20px);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >{`${type ? tagNameByValue[type] : ''}${location && type ? ' • ' : ''} ${
            location ? location : ''
          }`}</Text>
        </div>
        <Text variant="H3">{title}</Text>
        <div
          css={css`
            padding-top: 4px;
            display: flex;
            gap: 8px;
          `}
        >
          {opApproved && (
            <HoverTooltip text="Off Piste Approved">
              <div
                css={css`
                  width: 32px;
                  height: 32px;
                `}
              >
                <Icon
                  icon="OpApprovedSmall"
                  css={css`
                    svg {
                      height: 32px;
                      width: 32px;
                    }
                  `}
                />
              </div>
            </HoverTooltip>
          )}
          {sustainable && (
            <HoverTooltip text="Sustainable">
              <div
                css={css`
                  width: 32px;
                `}
              >
                <Icon
                  icon="SustainableSmall"
                  css={css`
                    svg {
                      height: 32px;
                      width: 32px;
                    }
                  `}
                />
              </div>
            </HoverTooltip>
          )}
        </div>
      </div>
    </Link>
  );
};

export default CardTile;
