import { getAccessToken } from '@auth0/nextjs-auth0';
import { css } from '@emotion/react';
import axios from 'axios';
import { GetServerSideProps } from 'next';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { Activity, Supplier, Venue } from 'schemaTypes';
import { BottomSheet } from 'src/lib/components/BottomSheet';
import CardFilters from 'src/lib/components/filters/CardFilters';
import CardTile from 'src/lib/components/CardTile';
import { CheckBoxEntry } from 'src/lib/components/filters/FilterDropdown';
import Button from 'src/lib/components/forms/Button';
import SearchInput from 'src/lib/components/forms/SearchInput';
import SearchSelect, { LocationOption } from 'src/lib/components/forms/SearchSelect';
import Tag from 'src/lib/components/Tag';
import { Container } from 'src/lib/layout/Container';
import Layout from 'src/lib/layout/Layout';
import Row from 'src/lib/layout/Row';
import { search } from 'src/lib/sanity/requests/search';
import { DESKTOP, TABLET } from 'src/lib/theme/breakpoints';
import withAuthRequired from '../../lib/auth';
import { Text } from '../../lib/components/Text';
import {
  CATEGORY_TYPE_RADIO_BUTTONS,
  createLocationCheckboxes,
  removeUnusedAndTransform,
} from 'src/lib/components/filters/filters';
import SortDropdown, { SortSettingType } from 'src/lib/components/SortDropdown';
import CustomCardView from 'src/lib/components/CustomCardView';
import SearchResultGrid from 'src/lib/components/SearchResultGrid';

export type Tag = { category: string; title: string; _id: string };

type CardsProps = {
  tags: Tag[];
  accessToken: any;
  locationSelectOptions: LocationOption[];
  featuredCardsLists: {
    featuredCards: {
      title: string;
      cards: (Venue | Activity | Supplier)[];
    }[];
  };
};

export type AllFiltersType = { [key: string]: CheckBoxEntry[] };

export const DOCS_BATCH_SIZE = 30;
const CardsPage = ({ accessToken, locationSelectOptions, featuredCardsLists }: CardsProps) => {
  const [category, setCategory] = useState<string | undefined>();
  const [data, setData] = useState<(Venue | Activity | Supplier)[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [docsRetrieved, setDocsRetrieved] = useState(0);
  const [isMoreDocs, setIsMoreDocs] = useState(true);
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);
  const [capacity, setCapacity] = useState(0);
  const [location, setLocation] = useState<LocationOption | undefined>();
  const [allFilters, setAllFilters] = useState<AllFiltersType>({
    location: createLocationCheckboxes(locationSelectOptions, location),
    category: CATEGORY_TYPE_RADIO_BUTTONS,
  });
  const [sortSetting, setSortSetting] = useState<SortSettingType>({
    sortOn: 'relevance',
    ascending: false,
  });

  const setCategoryAndResetFilters = (category: string) => {
    setAllFilters({
      location: createLocationCheckboxes(locationSelectOptions, location),
      category: CATEGORY_TYPE_RADIO_BUTTONS,
    });
    setCapacity(0);
    setSearchTerm('');
    setCategory(category);
  };

  const filterToTags = (allFilters: AllFiltersType) => {
    const tags = [];
    for (const key in allFilters) {
      tags.push(
        allFilters[key]
          .filter(({ selected }) => selected)
          .map(({ label, value }) => (
            <Tag
              key={value}
              title={label}
              onClose={() => {
                setAllFilters({
                  ...allFilters,
                  [key]: [
                    ...allFilters[key].map((filter) =>
                      filter.value === value ? { ...filter, selected: false } : filter,
                    ),
                  ],
                });
              }}
            />
          )),
      );
    }
    return tags;
  };
  const compareArrays = (array1?: string[], array2?: string[]) => {
    if (typeof array1 === 'undefined' || typeof array2 === 'undefined') {
      return 0;
    }
    let matches = 0;
    array1.forEach((i) => {
      array2.includes(i) && matches++;
    });
    return matches / array1.length;
  };
  const rateMatch = (allFilters: { [key: string]: string[] }, doc: Venue | Supplier | Activity) => {
    let matchScore = 0;
    for (const key in allFilters) {
      const filter = allFilters[key];
      let filterMatch = 0;
      if (key === 'location' && filter && filter.length > 0) {
        const { city, country } = doc.generalInfo?.address ?? {};
        const cityAndCountry = [];
        city && cityAndCountry.push(city);
        country && cityAndCountry.push(country);
        filterMatch = compareArrays(filter, cityAndCountry);
      } else if (key in doc) {
        //@ts-ignore
        filterMatch = compareArrays(filter, doc[key]);
      }
      matchScore += filterMatch;
    }
    return matchScore;
  };
  const rateDocumentMatch = (allFilters: AllFiltersType, doc: Venue | Supplier | Activity) => {
    const filtersActiveOnly = removeUnusedAndTransform(allFilters);
    return rateMatch(filtersActiveOnly, doc);
  };
  const sortDocuments = (docs: (Venue | Supplier | Activity)[], sortSetting: SortSettingType) => {
    const { sortOn, ascending } = sortSetting;
    switch (sortOn) {
      case 'relevance':
        return docs
          .map((doc) => ({ ...doc, match: rateDocumentMatch(allFilters, doc) }))
          .sort((a, b) => (ascending ? b.match - a.match : a.match - b.match));
      case 'lastUpdated':
        return docs.sort((a, b) => {
          const timeA = new Date(a._updatedAt).getTime();
          const timeB = new Date(b._updatedAt).getTime();
          return ascending ? timeA - timeB : timeB - timeA;
        });
      case 'lastAdded':
        return docs.sort((a, b) => {
          const timeA = new Date(a._createdAt).getTime();
          const timeB = new Date(b._createdAt).getTime();
          return ascending ? timeA - timeB : timeB - timeA;
        });
      case 'alphabetically':
        return docs.sort((a, b) =>
          sortSetting.ascending
            ? a.generalInfo?.title?.localeCompare(b.generalInfo?.title ?? '') ?? 0
            : b.generalInfo?.title?.localeCompare(a.generalInfo?.title ?? '') ?? 0,
        );
      default:
        return docs;
    }
  };
  const sortChanged = (sortSetting: SortSettingType) => {
    const sortedDocs = sortDocuments(data, sortSetting);
    setData(sortedDocs);
    setSortSetting(sortSetting);
  };
  const locationChanged = (newLocation: LocationOption) => {
    setLocation(newLocation);
    setAllFilters({
      ...allFilters,
      location: createLocationCheckboxes(locationSelectOptions, newLocation),
    });
  };

  const getData = async (start: number, loadMore: boolean) => {
    if (accessToken && (searchTerm || category || loadMore)) {
      const newDocs = await search(
        searchTerm,
        accessToken,
        start,
        start + DOCS_BATCH_SIZE,
        removeUnusedAndTransform(allFilters),
        capacity,
        category,
        allFilters.location.filter(({ selected }) => selected).map(({ options }) => options),
      );
      setIsMoreDocs(newDocs.length === DOCS_BATCH_SIZE);
      loadMore
        ? setData(sortDocuments([...data, ...newDocs], sortSetting))
        : setData(sortDocuments(newDocs, sortSetting));
      loadMore ? setDocsRetrieved(docsRetrieved + newDocs.length) : setDocsRetrieved(0);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    getData(0, false);
    setDocsRetrieved(0);
  }, [searchTerm, category, allFilters, location, capacity]);

  return (
    <Layout>
      <Container
        size="medium"
        css={css`
          margin: 40px auto 0 auto;
          @media (min-width: ${TABLET}px) {
            margin-top: 60px;
          }
        `}
      >
        <Row
          align="center"
          justify="space-between"
          wrap="wrap"
          css={css`
            margin-bottom: 40px;
          `}
        >
          <Text variant="H1">Cards</Text>
          <Link href="/cards/create-card">
            <Button label="New Card" icon="Add" />
          </Link>
        </Row>
        {!Boolean(category) && (
          <div
            css={css`
              margin-bottom: 80px;
              gap: 70px;
              display: grid;
              grid-template-columns: 1fr;
              @media (min-width: ${DESKTOP}px) {
                margin-bottom: 0px;
                grid-template-columns: 1fr 1fr;
              }
            `}
          >
            <div>
              <Text
                variant="H2"
                css={css`
                  margin-bottom: 20px;
                `}
              >
                Where are you going?
              </Text>
              <SearchSelect
                optionSelected={locationChanged}
                locationSelectOptions={locationSelectOptions}
              />
            </div>
            <div>
              <Text
                variant="H2"
                css={css`
                  margin-bottom: 20px;
                `}
              >
                What are you looking for?
              </Text>
              <div
                css={css`
                  display: flex;
                  gap: 12px;
                  flex-direction: column; ;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    gap: 12px;
                    flex-direction: column;
                    @media (min-width: ${TABLET}px) {
                      flex-direction: row;
                    }
                  `}
                >
                  <Button
                    label="Venues"
                    onClick={() => setCategoryAndResetFilters('venue')}
                    css={css`
                      flex-grow: 1;
                    `}
                  />
                  <Button
                    label="Entertainment"
                    onClick={() => setCategoryAndResetFilters('entertainment')}
                    css={css`
                      flex-grow: 1;
                    `}
                  />
                </div>
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    gap: 12px;
                    flex-direction: column;
                    @media (min-width: ${TABLET}px) {
                      flex-direction: row;
                    }
                  `}
                >
                  <Button
                    label="Suppliers"
                    onClick={() => setCategoryAndResetFilters('supplier')}
                    css={css`
                      flex-grow: 1;
                    `}
                  />
                  <Button
                    label="Activities"
                    onClick={() => setCategoryAndResetFilters('activity')}
                    css={css`
                      flex-grow: 1;
                    `}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {(Boolean(location) || Boolean(category)) && (
          <SearchInput onValue={setSearchTerm} value={searchTerm} />
        )}
      </Container>

      {Boolean(category) || Boolean(location) ? (
        <Container>
          <>
            <Button
              onClick={() => setBottomSheetVisible(true)}
              css={css`
                margin-top: 24px;
                width: 100%;
                @media (min-width: ${TABLET}px) {
                  display: none;
                }
              `}
              label="Filters"
            />
            <div
              css={css`
                display: grid;
                gap: 40px;
                grid-template-columns: 1fr;
                @media (min-width: ${TABLET}px) {
                  grid-template-columns: ${Boolean(category) ? '1fr 3fr' : '1fr'};
                }
              `}
            >
              <CardFilters
                categories={CATEGORY_TYPE_RADIO_BUTTONS}
                category={category}
                setCategory={setCategoryAndResetFilters}
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                capacity={capacity}
                onCapacityChange={setCapacity}
              />
              <div>
                <Row
                  justify="space-between"
                  align="center"
                  css={css`
                    margin-top: 40px;
                    margin-bottom: 12px;
                    @media (min-width: ${TABLET}px) {
                      margin-bottom: 40px;
                      margin-top: 60px;
                    }
                  `}
                >
                  <Text variant="H2">Search Results</Text>
                  <SortDropdown sortChanged={sortChanged} />
                </Row>
                <Text
                  variant="Caption"
                  css={css`
                    text-transform: uppercase;
                  `}
                >
                  Displaying {data.length} results for:
                </Text>
                <Row
                  wrap="wrap"
                  gap={12}
                  css={css`
                    margin-top: 8px;
                    margin-bottom: 32px;
                  `}
                >
                  {filterToTags(allFilters)}
                </Row>
                <SearchResultGrid>
                  {data.map((item, i) => (
                    <CardTile
                      key={i}
                      image={item.generalInfo?.mainImage}
                      type={item._type}
                      title={item.generalInfo?.title}
                      opApproved={item.internalInfo?.opApproved ?? false}
                      sustainable={item.internalInfo?.sustainable ?? false}
                      slug={item.generalInfo?.slug?.current}
                      location={item.generalInfo?.address?.city}
                    />
                  ))}
                </SearchResultGrid>
              </div>
            </div>
          </>
        </Container>
      ) : (
        <Container
          fullWidth
          css={css`
            @media (min-width: ${DESKTOP}px) {
              margin: auto;
              max-width: 940px;
            }
          `}
        >
          <CustomCardView featuredCardsLists={featuredCardsLists} />
        </Container>
      )}
      <BottomSheet visible={bottomSheetVisible} onClose={() => setBottomSheetVisible(false)}>
        <CardFilters
          categories={CATEGORY_TYPE_RADIO_BUTTONS}
          category={category}
          setCategory={setCategoryAndResetFilters}
          allFilters={allFilters}
          setAllFilters={setAllFilters}
          inBottomSheet
          capacity={capacity}
          onCapacityChange={setCapacity}
        />
      </BottomSheet>
      {isMoreDocs && category && location && (
        <Container
          css={css`
            margin-top: 26px;
          `}
        >
          <Button icon="Add" label="Load more" onClick={() => getData(docsRetrieved, true)} />
        </Container>
      )}
    </Layout>
  );
};
export const getServerSideProps: GetServerSideProps<CardsProps> = async ({ req, res }) => {
  let accessToken = '';
  let tags = [];
  let locationSelectOptions = [];
  let featuredCardsLists = [];
  try {
    const tokenResponse = await getAccessToken(req, res);
    accessToken = tokenResponse.accessToken ?? '';
    const tagsResponse = await axios.get(`${process.env.API_URL}/api/tags`, {
      headers: { Cookie: req.headers.cookie ?? '' },
    });
    tags = tagsResponse.data;
    const countriesResponse = await axios.get(`${process.env.API_URL}/api/countries`, {
      headers: { Cookie: req.headers.cookie ?? '' },
    });
    locationSelectOptions = countriesResponse.data;
  } catch (err) {
    console.error('Could not get access token');
  }
  try {
    const featuredCardsListsRespons = await axios.get(`${process.env.API_URL}/api/cards/featured`, {
      headers: { Cookie: req.headers.cookie ?? '' },
    });
    featuredCardsLists = featuredCardsListsRespons.data;
  } catch (err) {
    console.error('Could not get featured cards lists', err);
  }

  return {
    props: { tags, accessToken, locationSelectOptions, featuredCardsLists },
  };
};

export default withAuthRequired(CardsPage, 'cards');
