import { css } from '@emotion/react';
import React, { useRef, useState } from 'react';
import Row from 'src/lib/layout/Row';
import Slider from '../forms/Slider';
import Icon from '../icon/Icon';
import { Text } from '../Text';
type SliderDropdownProps = {
  label: string;
  onValue: (value: number) => void;
  defaultExpanded?: boolean;
  value?: number;
  max?: number;
  min?: number;
};
const SliderDropdown = ({
  onValue,
  label,
  value,
  defaultExpanded = false,
  max,
  min,
}: SliderDropdownProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const sliderWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <Row justify="space-between">
        <Row
          onClick={() => setExpanded(!expanded)}
          css={css`
            cursor: pointer;
          `}
        >
          <Icon icon={!expanded ? 'ChevronDown' : 'ChevronUp'} size={24} />
          <Text variant="Body">{label}</Text>
        </Row>
      </Row>
      <div
        css={css`
          padding-left: 24px;
          transition: height 0.2s ease-out;
          overflow: hidden;
          box-sizing: border-box;
          height: 0;
          ${expanded &&
          css`
            height: max-content;
            height: ${sliderWrapperRef.current ? sliderWrapperRef.current.clientHeight : '0'}px;
          `}
        `}
      >
        <div ref={sliderWrapperRef}>
          <Slider onValue={onValue} min={min} max={max} value={value} />
        </div>
      </div>
    </div>
  );
};

export default SliderDropdown;
