import axios from 'axios';
import { LocationOption } from 'src/lib/components/forms/SearchSelect';

export const search = async (
  searchTerm: string,
  token: string,
  start: number = 0,
  stop: number = 100,
  allFilters: { [key: string]: string[] },
  minCapacity?: number,
  category?: string,
  locations?: LocationOption[],
) => {
  try {
    const res = await axios.post(`/api/search`, {
      headers: { Authorization: `Bearer ${token}` },
      data: { searchTerm, start, stop, category, allFilters, locations, minCapacity },
    });
    return res.data;
  } catch (err) {
    console.error('Error fetching collections');
    return [];
  }
};
