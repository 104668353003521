import { css } from '@emotion/react';
import React from 'react';
import colors from 'src/lib/theme/colors';
import { Text, textVariantStyles } from '../Text';

type SearchInputProps = {
  onValue: (value: string) => void;
  value?: string;
  placeholder?: string;
  label?: string;
  hideLabel?: boolean;
  errorMessage?: string;
};

const SearchInput = ({
  onValue,
  placeholder = 'Search for Experiences',
  value,
  label = 'SEARCH FOR EXPERIENCES',
  hideLabel = false,
  errorMessage,
  ...rest
}: SearchInputProps & React.HTMLAttributes<HTMLElement>) => {
  return (
    <div {...rest}>
      {!hideLabel && (
        <label>
          <Text
            variant="Caption"
            css={css`
              margin-bottom: 8px;
              text-transform: uppercase;
            `}
          >
            {label}
          </Text>
        </label>
      )}
      <input
        onChange={({ target }) => onValue(target.value)}
        value={value}
        placeholder={placeholder}
        css={css`
          width: 100%;
          outline: none;
          border: 1px solid ${errorMessage ? colors.error : colors.lightGray};
          border-radius: 0;
          padding: 11px 48px 11px 32px;
          ${textVariantStyles.Body}
          background: url(icons/search.png) no-repeat scroll;
          background-size: 30px;
          background-position: calc(100% - 18px) 50%;
          background-color: #ffffff;
        `}
      />
      {errorMessage && (
        <Text
          variant="CaptionSmall"
          color={colors.error}
          css={css`
            margin-top: 6px;
          `}
        >
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default SearchInput;
