import { css } from '@emotion/react';
import React from 'react';
import { TABLET } from '../../theme/breakpoints';
import FilterColumn from './FilterColumn';
import FilterDropdown, { CheckBoxEntry } from './FilterDropdown';
import {
  ACTIVITY_TYPE_CHECKBOXES,
  ATMOSPHERE_CHECKBOXES,
  VENUE_OCCASION_CHECKBOXES,
  PRICE_RANGE_CHECKBOXES,
  STARS_CHECKBOXES,
  SUPPLIER_TYPE_CHECKBOXES,
  TIME_OF_DAY_CHECKBOXES,
  VENUE_SUBCATEGORY_CHECKBOXES,
  VENUE_TYPE_CHECKBOXES,
  AVILABLILLITY_CHECKBOXES,
  ENTERTEINMENT_TYPE_CHECKBOXES,
  ENTERTEINMENT_OCCASION_CHECKBOXES,
} from './filters';
import { AllFiltersType } from 'src/pages/cards';
import SliderDropdown from './SliderDropdown';

type CardFiltersProps = {
  category?: string;
  categories?: { label: string; value: string }[];
  setCategory: (category: string) => void;
  allFilters: AllFiltersType;
  setAllFilters: (filters: AllFiltersType) => void;
  inBottomSheet?: boolean;
  onCapacityChange?: (value: number) => void;
  maxCapacity?: number;
  minCapacity?: number;
  capacity?: number;
};

const CardFilters = ({
  category,
  categories,
  setCategory,
  allFilters,
  setAllFilters,
  inBottomSheet = false,
  onCapacityChange,
  maxCapacity,
  minCapacity,
  capacity = 0,
}: CardFiltersProps) => {
  const filterChanged = (filters: CheckBoxEntry[], category: string) => {
    setAllFilters({
      ...allFilters,
      [category]: filters,
    });
  };
  return (
    <FilterColumn
      inBottomSheet={inBottomSheet}
      css={
        !inBottomSheet &&
        css`
          display: none;
          @media (min-width: ${TABLET}px) {
            display: initial;
          }
        `
      }
    >
      {categories && (
        <FilterDropdown
          defaultExpanded
          groupName="category"
          label="Category"
          value={category}
          onSelect={setCategory}
          reverseOrder={false}
          radioButtons={categories}
        />
      )}
      {category === 'venue' && (
        <>
          <FilterDropdown
            label="Venue type"
            onFilterChange={(filters) => filterChanged(filters, '_type')}
            reverseOrder={false}
            value={allFilters._type}
            checkboxes={VENUE_TYPE_CHECKBOXES}
          />
          <FilterDropdown
            label="Occasion"
            onFilterChange={(filters) => filterChanged(filters, 'occasion')}
            reverseOrder={false}
            value={allFilters.occasion}
            checkboxes={VENUE_OCCASION_CHECKBOXES}
          />
          <FilterDropdown
            label="Atmosphere"
            onFilterChange={(filters) => filterChanged(filters, 'atmosphere')}
            reverseOrder={false}
            value={allFilters.atmosphere}
            checkboxes={ATMOSPHERE_CHECKBOXES}
          />
          <FilterDropdown
            label="Number of stars"
            onFilterChange={(filters) => filterChanged(filters, 'numberOfStars')}
            reverseOrder={false}
            value={allFilters.numberOfStars}
            checkboxes={STARS_CHECKBOXES}
          />
          <FilterDropdown
            label="Subcategory"
            onFilterChange={(filters) => filterChanged(filters, 'subcategory')}
            reverseOrder={false}
            value={allFilters.subcategory}
            checkboxes={VENUE_SUBCATEGORY_CHECKBOXES}
          />
        </>
      )}
      {onCapacityChange && (category === 'venue' || category === 'activity') && (
        <SliderDropdown
          label="Capacity"
          max={maxCapacity}
          min={minCapacity}
          value={capacity}
          onValue={onCapacityChange}
        />
      )}
      {'location' in allFilters && (
        <FilterDropdown
          label="Destination"
          onFilterChange={(filters) => filterChanged(filters, 'location')}
          reverseOrder={false}
          checkboxes={allFilters.location}
          value={allFilters.location}
        />
      )}
      {(category === 'venue' || category === 'entertainment' || category === 'supplier') && (
        <FilterDropdown
          label="Price range"
          onFilterChange={(filters) => filterChanged(filters, 'priceRange')}
          reverseOrder={false}
          value={allFilters.priceRange}
          checkboxes={PRICE_RANGE_CHECKBOXES}
        />
      )}
      {(category === 'entertainment' || category === 'supplier') && (
        <FilterDropdown
          label="Avilablillity"
          onFilterChange={(filters) => filterChanged(filters, 'avilablillity')}
          reverseOrder={false}
          value={allFilters.avilablillity}
          checkboxes={AVILABLILLITY_CHECKBOXES}
        />
      )}
      {category === 'activity' && (
        <>
          <FilterDropdown
            label="Time of day"
            onFilterChange={(filters) => filterChanged(filters, 'timeOfDay')}
            reverseOrder={false}
            value={allFilters.timeOfDay}
            checkboxes={TIME_OF_DAY_CHECKBOXES}
          />
          <FilterDropdown
            label="Activity type"
            onFilterChange={(filters) => filterChanged(filters, '_type')}
            reverseOrder={false}
            value={allFilters._type}
            checkboxes={ACTIVITY_TYPE_CHECKBOXES}
          />
        </>
      )}

      {category === 'supplier' && (
        <>
          <FilterDropdown
            label="Supplier type"
            onFilterChange={(filters) => filterChanged(filters, '_type')}
            reverseOrder={false}
            value={allFilters._type}
            checkboxes={SUPPLIER_TYPE_CHECKBOXES}
          />
        </>
      )}
      {category === 'entertainment' && (
        <>
          <FilterDropdown
            label="Supplier type"
            onFilterChange={(filters) => filterChanged(filters, '_type')}
            reverseOrder={false}
            value={allFilters._type}
            checkboxes={ENTERTEINMENT_TYPE_CHECKBOXES}
          />
          <FilterDropdown
            label="Occasion"
            onFilterChange={(filters) => filterChanged(filters, 'occasion')}
            reverseOrder={false}
            value={allFilters.occasion}
            checkboxes={ENTERTEINMENT_OCCASION_CHECKBOXES}
          />
        </>
      )}
    </FilterColumn>
  );
};

export default CardFilters;
