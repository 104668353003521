import { useEffect } from 'react';

export type Key =
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'ArrowUp'
  | 'ArrowDown'
  | 'ControlRight'
  | 'ShiftRight'
  | 'Enter'
  | 'Backspace'
  | 'ContextMenu'
  | 'MetaRight'
  | 'AltRight'
  | 'Space'
  | 'AltLeft'
  | 'MetaLeft'
  | 'ControlLeft'
  | 'ShiftLeft'
  | 'IntlBackslash'
  | 'CapsLock'
  | 'Tab'
  | 'Backquote'
  | 'Digit1'
  | 'Digit2'
  | 'Digit3'
  | 'Digit4'
  | 'Digit5'
  | 'Digit6'
  | 'Digit7'
  | 'Digit8'
  | 'Digit9'
  | 'Digit0'
  | 'Minus'
  | 'Equal'
  | 'KeyZ'
  | 'KeyX'
  | 'KeyC'
  | 'KeyV'
  | 'KeyB'
  | 'KeyN'
  | 'KeyM'
  | 'Comma'
  | 'Period'
  | 'Slash'
  | 'KeyA'
  | 'KeyS'
  | 'KeyD'
  | 'KeyF'
  | 'KeyG'
  | 'KeyH'
  | 'KeyJ'
  | 'KeyK'
  | 'KeyL'
  | 'Semicolon'
  | 'Quote'
  | 'Backslash'
  | 'KeyQ'
  | 'KeyW'
  | 'KeyE'
  | 'KeyR'
  | 'KeyT'
  | 'KeyY'
  | 'KeyU'
  | 'KeyI'
  | 'KeyO'
  | 'KeyP'
  | 'BracketLeft'
  | 'BracketRight'
  | 'Escape'
  | 'F1'
  | 'F2'
  | 'F3'
  | 'F4'
  | 'F5'
  | 'F6'
  | 'F7'
  | 'F8'
  | 'F9'
  | 'F10'
  | 'F11'
  | 'F12'
  | 'F12'
  | 'ScrollLock'
  | 'Pause'
  | 'Insert'
  | 'Home'
  | 'PageUp'
  | 'Delete'
  | 'End'
  | 'PageDown'
  | 'Numpad0'
  | 'Numpad1'
  | 'Numpad2'
  | 'Numpad3'
  | 'Numpad4'
  | 'Numpad5'
  | 'Numpad6'
  | 'Numpad7'
  | 'Numpad8'
  | 'Numpad9'
  | 'NumpadDecimal'
  | 'NumpadEnter'
  | 'NumpadAdd'
  | 'NumpadSubtract'
  | 'NumpadMultiply'
  | 'NumpadDivide'
  | 'NumLock';

export type ModifierKey = 'ALT' | 'CTRL' | 'SHIFT';

/** Given a keyboard key and a callback, call the callback if the key is pressed. */
export function useKeyPressCallback(args: {
  /** They key that will trigger the callback. */
  key: Key;
  /** The callback that will be called when the keys are pressed. */
  callback: Function;
  /** Optional: Modifier keys that must be pressed for the callback to be called. */
  modifiers?: ModifierKey[];
}) {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (args.modifiers) {
        if (args.modifiers.includes('CTRL') && !event.ctrlKey) return;
        if (args.modifiers.includes('ALT') && !event.altKey) return;
        if (args.modifiers.includes('SHIFT') && !event.shiftKey) return;
      }
      if (event.code !== args.key) return;
      args.callback();
    };
    document.body.addEventListener('keydown', handler);
    return () => document.body.removeEventListener('keydown', handler);
  }, [args]);
}
