import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import Row from '../../layout/Row';
import Checkbox from '../forms/Checkbox';
import RadioButton from '../forms/RadioButton';
import Icon from '../icon/Icon';
import { Text } from '../Text';
export type CheckBoxEntry = {
  label: string;
  value: string;
  selected?: boolean;
  options?: any;
};
type FilterDropdownCheckboxesProps = {
  label: string;
  checkboxes: CheckBoxEntry[];
  onFilterChange: (filters: CheckBoxEntry[]) => void;
  reverseOrder?: boolean;
  defaultExpanded?: boolean;
  value?: CheckBoxEntry[];
};
type FilterDropdownRadioProps = {
  label: string;
  radioButtons: { label: string; value: string }[];
  reverseOrder?: boolean;
  groupName: string;
  onSelect: (value: string) => void;
  initialValue?: string;
  value?: string;
  defaultExpanded?: boolean;
};

const FilterDropdownCheckboxes = ({
  checkboxes,
  onFilterChange,
  reverseOrder = false,
  label,
  defaultExpanded = false,
  value,
}: FilterDropdownCheckboxesProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const checkBowWrapperRef = useRef<HTMLDivElement>(null);

  const [filters, setFilters] = useState<CheckBoxEntry[]>(
    checkboxes.map((c) => ({
      value: c.value,
      selected: c.selected ?? false,
      label: c.label,
      options: c.options,
    })),
  );
  const onCheck = (value: string) => {
    const filtersUpdated = filters.map((f) =>
      f.value === value ? { value, selected: !f.selected, label: f.label, options: f.options } : f,
    );
    setFilters(filtersUpdated);
    onFilterChange(filtersUpdated);
  };
  const clearFilters = () => {
    const filtersUpdated = checkboxes.map((c) => ({
      value: c.value,
      selected: false,
      label: c.label,
      options: c.options,
    }));
    setFilters(filtersUpdated);
    onFilterChange(filtersUpdated);
  };
  useEffect(() => {
    if (value) {
      setFilters(value);
    }
  }, [value]);
  return (
    <div>
      <Row justify="space-between">
        <Row
          onClick={() => setExpanded(!expanded)}
          css={css`
            cursor: pointer;
          `}
        >
          <Icon icon={!expanded ? 'ChevronDown' : 'ChevronUp'} size={24} />
          <Text variant="Body">{label}</Text>
        </Row>
        {filters.some(({ selected }) => selected) && (
          <Icon
            onClick={clearFilters}
            icon="DeleteCheck"
            size={24}
            css={css`
              cursor: pointer;
            `}
          />
        )}
      </Row>
      <div
        css={css`
          padding-left: 24px;
          transition: height 0.2s ease-out;
          overflow: hidden;
          height: 0;
          ${expanded &&
          css`
            height: ${checkBowWrapperRef.current
              ? checkBowWrapperRef.current.offsetHeight + 24
              : '0'}px;
            overflow: hidden;
          `}
        `}
      >
        <div ref={checkBowWrapperRef}>
          {filters.map((c) => (
            <div
              key={c.value}
              css={css`
                height: max-content;
                margin: 14px 0;
              `}
            >
              <Checkbox
                label={c.label}
                onValue={() => onCheck(c.value)}
                selected={c.selected}
                reverseOrder={reverseOrder}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const FilterDropdownRadio = ({
  radioButtons,
  reverseOrder = false,
  groupName,
  onSelect,
  label,
  value,
  defaultExpanded = false,
}: FilterDropdownRadioProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const checkBowWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <Row justify="space-between">
        <Row
          onClick={() => setExpanded(!expanded)}
          css={css`
            cursor: pointer;
          `}
        >
          <Icon icon={!expanded ? 'ChevronDown' : 'ChevronUp'} size={24} />
          <Text variant="Body">{label}</Text>
        </Row>
      </Row>
      <div
        css={css`
          padding-left: 24px;
          transition: height 0.2s ease-out;
          overflow: hidden;
          height: 0;
          ${expanded &&
          css`
            height: ${checkBowWrapperRef.current ? checkBowWrapperRef.current.clientHeight : '0'}px;
            overflow: hidden;
          `}
        `}
      >
        <div ref={checkBowWrapperRef}>
          <RadioButton.Group
            name={groupName}
            onSelect={(value) => onSelect(value as string)}
            value={value}
          >
            {radioButtons.map((c, i) => (
              <RadioButton key={i} label={c.label} value={c.value} reverseOrder={reverseOrder} />
            ))}
          </RadioButton.Group>
        </div>
      </div>
    </div>
  );
};

const FilterDropdown = ({ ...rest }: FilterDropdownCheckboxesProps | FilterDropdownRadioProps) =>
  'checkboxes' in rest ? (
    <FilterDropdownCheckboxes {...(rest as FilterDropdownCheckboxesProps)} />
  ) : (
    <FilterDropdownRadio {...(rest as FilterDropdownRadioProps)} />
  );

export default FilterDropdown;
