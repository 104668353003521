import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { css } from '@emotion/react';
import FocusLock from 'react-focus-lock';
import { AnimatePresence, motion } from 'framer-motion';
import { isInBrowser } from '../utils/isInBrowser';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { useKeyPressCallback } from '../hooks/useKeyPressCallback';
import colors from '../theme/colors';

const transitionTime = 0.4;

export type BottomSheetProps = {
  /** If true, show the bottom sheet. */
  visible: boolean;

  /** Called if the user clicks outside the sheet or on the drag to close.
   * In the passed function, set the necessary state to close the sheet. */
  onClose?: () => void;
};

export const BottomSheet = ({
  visible,
  onClose = () => ({}),
  children,
}: BottomSheetProps & React.HTMLAttributes<HTMLElement>) => {
  const onCloseHandler = () => {
    onClose();
  };
  if (!isInBrowser()) return <></>;
  else
    return createPortal(
      <AnimatePresence exitBeforeEnter>
        {visible && (
          <motion.div
            key="modal-outer"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: transitionTime, ease: 'easeInOut' } }}
            exit={{ opacity: 0, transition: { duration: transitionTime, ease: 'easeInOut' } }}
            css={css`
              display: flex;
              justify-content: flex-end;
              z-index: 100;
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: rgba(74, 80, 113, 0.4);
            `}
          >
            <motion.div
              key="modal-inner"
              role="dialog"
              initial={{ y: '100vh' }}
              animate={{
                y: '0vh',
                transition: { duration: transitionTime, ease: 'easeInOut' },
              }}
              exit={{
                y: '100vh',
                transition: { duration: transitionTime, ease: 'easeInOut' },
              }}
              css={css`
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: fit-content;
                overflow-y: scroll;
                background-color: ${colors.background};
                max-height: 60vh;
                overflow-y: scroll;
              `}
            >
              {createPortal(
                <style>{`
                  html, body { 
                    overflow: hidden; 
                    position:absolute;
                    top: -${window.scrollY}px;
                    inset-inline-end: 0px;
                    height:${window.innerHeight}px;
                    width:100vw;
                  }
                  `}</style>,
                document.head,
              )}
              <InnerComponent onClose={onCloseHandler}>
                <FocusLock returnFocus>
                  <a
                    onClick={onCloseHandler}
                    onTouchMove={onCloseHandler}
                    css={css`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 44px;
                      position: sticky;
                      top: 0;
                      background-color: ${colors.background};
                      width: 100%;
                      z-index: 100;
                    `}
                  >
                    <div
                      css={css`
                        background-color: #fff;
                        width: 60px;
                        height: 8px;
                        border-radius: 99999px;
                      `}
                    />
                  </a>
                  <div
                    css={css`
                      padding: 0px 0 0px 0;
                      position: relative;
                      height: fit-content;
                    `}
                  >
                    <div>{children}</div>
                  </div>
                </FocusLock>
              </InnerComponent>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>,
      document.body,
    );
};

/** Handles user interactions that will trigger closing the bottom sheet, that is, clicking outside of it,
 * or pressing `Escape`. */
const InnerComponent: React.FC<{
  onClose: () => void;
}> = ({ onClose, children }) => {
  const ref = useRef(null);
  useOnClickOutside(ref, onClose);
  useKeyPressCallback({ key: 'Escape', callback: () => onClose() });
  return <div ref={ref}>{children}</div>;
};
