import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import colors from 'src/lib/theme/colors';
import { Text } from '../Text';

type RadioButtonProps = {
  label: string;
  reverseOrder?: boolean;
  name?: string;
  value: string;
  onValue?: (value: string) => void;
  selectedValue?: string;
};

const RadioButton = ({
  label,
  reverseOrder = false,
  name,
  value,
  onValue,
  selectedValue,
}: RadioButtonProps) => {
  const [currentSelected, setCurrentSelected] = useState(selectedValue);
  useEffect(() => {
    setCurrentSelected(selectedValue);
  }, [selectedValue]);
  const onChange = () => {
    setCurrentSelected(value);
    onValue && onValue(value);
  };
  return (
    <label
      css={css`
        display: flex;
        align-items: center;
        position: relative;
        gap: 12px;
        /* padding-left: 35px; */
        padding: 8px 0px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input:checked ~ .checkmark {
          background-color: black;
          :after {
            display: block;
          }
        }
        ${reverseOrder &&
        css`
          flex-direction: row-reverse;
          justify-content: space-between;
        `}
      `}
    >
      <input
        value={value}
        onChange={onChange}
        checked={value === currentSelected}
        type="radio"
        name={name}
        css={css`
          position: absolute;
          opacity: 0;
          cursor: pointer;
        `}
      />
      <span
        className="checkmark"
        css={css`
          position: relative;
          height: 25px;
          width: 25px;
          background-color: transparent;
          outline: 1px solid ${colors.type};
          border-radius: 50%;
          :after {
            content: '';
            position: absolute;
            display: none;
            top: 11px;
            left: 11px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: white;
          }
        `}
      />
      <Text variant="Body" color={colors.darkGray}>
        {label}
      </Text>
    </label>
  );
};

const Group = ({
  children,
  name,
  onSelect,
  value,
}: {
  name: string;
  onSelect: (value: string) => void;
  value?: string;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const onChange = (buttonValue: string) => {
    setSelectedValue(buttonValue);
    onSelect(buttonValue);
  };
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { name, onValue: onChange, selectedValue });
    }
    return child;
  });
  return <div>{childrenWithProps}</div>;
};
RadioButton.Group = Group;
export default RadioButton;
