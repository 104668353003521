import { css } from '@emotion/react';
import React from 'react';
import { TABLET } from 'src/lib/theme/breakpoints';
import { Text } from '../Text';

type FilterColumnProps = { inBottomSheet?: boolean };

const FilterColumn = ({
  inBottomSheet,
  children,
  ...rest
}: FilterColumnProps & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={css`
        height: 100%;
        @media (min-width: ${TABLET}px) {
          width: 100%;
          overflow: hidden;
        }
        ${inBottomSheet &&
        css`
          padding: 24px;
        `}
      `}
      {...rest}
    >
      {!inBottomSheet && (
        <Text
          variant="H2"
          css={css`
            margin-top: 60px;
            margin-bottom: 40px;
          `}
        >
          Filters
        </Text>
      )}
      {children}
    </div>
  );
};

export default FilterColumn;
