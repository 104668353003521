import { css } from '@emotion/react';
import React, { Children } from 'react';
import { TABLET, DESKTOP } from '../theme/breakpoints';
import Icon from './icon/Icon';
import { Text } from './Text';

type Props = { title: string };

const Carusel = ({ title, children }: Props & React.HTMLAttributes<HTMLDivElement>) => {
  const [cardGroup, setCardGroup] = React.useState(1);
  const length = Children.toArray(children).length;

  return (
    <div
      css={css`
        position: relative;
        overflow: visible;
        @media (min-width: ${DESKTOP}px) {
          margin-right: -20px;
        }
      `}
    >
      <div
        css={css`
          overflow: hidden;
          > h2 {
            margin-bottom: 32px;
            padding-left: 20px;
            padding-right: 20px;
            @media (min-width: ${TABLET}px) {
              padding-left: 40px;
              padding-right: 40px;
            }
            @media (min-width: ${DESKTOP}px) {
              padding-left: 0px;
              padding-right: 0px;
            }
          }
        `}
      >
        <Text variant="H2">{title}</Text>
        <div
          css={css`
            display: grid;
            grid-auto-flow: column;
            overflow: scroll;
            grid-gap: 20px;

            padding-left: 20px;
            padding-right: 20px;

            @media (min-width: ${TABLET}px) {
              padding-left: 40px;
              padding-right: 40px;
            }
            @media (min-width: ${DESKTOP}px) {
              padding-left: 0px;
              padding-right: 0px;
            }
            grid-template-columns: repeat(${length}, 240px);
            ::-webkit-scrollbar {
              width: 0px;
              background: transparent;
            }
            @media (min-width: ${DESKTOP}px) {
              grid-gap: 0px;
              grid-template-columns: repeat(${length}, 25%);
              grid-auto-flow: column;
              overflow: visible;
              transform: translate(-${cardGroup - 1}00%);
              transition: transform 0.4s ease-in-out;
              > * {
                padding-right: 20px;
              }
            }
          `}
        >
          {children}
        </div>
      </div>
      {cardGroup > 1 && (
        <Icon
          icon="ChevronLeft"
          size={56}
          css={css`
            top: calc(50% - 12px);
            left: -65px;
            position: absolute;
            z-index: 5;
            cursor: pointer;
            display: none;
            @media (min-width: ${DESKTOP}px) {
              display: block;
            }
          `}
          onClick={() => setCardGroup(Math.max(cardGroup - 1, 1))}
        />
      )}
      {cardGroup < Math.ceil(length / 4) && (
        <Icon
          icon="ChevronRight"
          size={56}
          onClick={() => {
            setCardGroup(Math.min(cardGroup + 1, Math.ceil(length / 4)));
          }}
          css={css`
            top: calc(50% - 12px);
            right: -45px;
            position: absolute;
            z-index: 5;
            cursor: pointer;
            display: none;
            @media (min-width: ${DESKTOP}px) {
              display: block;
            }
          `}
        />
      )}
    </div>
  );
};

export default Carusel;
