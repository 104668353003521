import { css } from '@emotion/react';
import React from 'react';
import { DESKTOP, TABLET } from '../theme/breakpoints';

type SearchResultGridProps = {};

const SearchResultGrid = ({
  children,
}: SearchResultGridProps & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={css`
        width: 100%;
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr;
        @media (min-width: ${TABLET}px) {
          gap: 30px;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @media (min-width: ${DESKTOP}px) {
          gap: 40px;
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      `}
    >
      {children}
    </div>
  );
};

export default SearchResultGrid;
