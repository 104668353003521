import { css } from '@emotion/react';
import React, { useRef, useState } from 'react';
import { useOnClickOutside } from 'src/lib/hooks/useOnClickOutside';
import colors from 'src/lib/theme/colors';
import { Text } from '../Text';
import SearchInput from './SearchInput';
export type LocationOption = { country: string; city?: string; isCountryEntry?: boolean };
type SearchSelectProps = {
  locationSelectOptions: LocationOption[];
  optionSelected: (option: LocationOption) => void;
};

const SearchSelect = ({ locationSelectOptions, optionSelected }: SearchSelectProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [hideSearchResults, setHideSearchResults] = useState(true);
  const [searchResults, setSearchResults] = useState(locationSelectOptions);
  const [selectedSearchOption, setSelectedSearchOption] = useState<LocationOption | undefined>(
    undefined,
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setHideSearchResults(true);
    if (typeof selectedSearchOption === 'undefined') {
      setErrorMessage('An option from the list must be selected to filter on location');
    }
  });

  const searchTermChanged = (value: string, hideResults: boolean) => {
    setSelectedSearchOption(undefined);
    setErrorMessage(undefined);
    setHideSearchResults(hideResults);
    setSearchValue(value);
    setSearchResults(
      locationSelectOptions.filter(
        (item) =>
          item.country.toLowerCase().includes(value.toLowerCase()) ||
          item?.city?.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };

  const searchTermSelected = (option: LocationOption) => {
    setSelectedSearchOption(option);
    setHideSearchResults(true);
    setSearchValue(option.isCountryEntry ? option.country : `${option.city}, ${option.country}`);
    optionSelected(option);
  };

  return (
    <div
      ref={ref}
      css={css`
        position: relative;
        z-index: 1;
      `}
    >
      <SearchInput
        hideLabel
        onValue={(value) => searchTermChanged(value, false)}
        value={searchValue}
        errorMessage={errorMessage}
      />
      {!(searchValue.length === 0) && !hideSearchResults && searchResults.length > 0 && (
        <div
          css={css`
            width: 100%;
            background-color: white;
            border: 1px solid ${colors.lightGray};
            position: absolute;
          `}
        >
          {searchResults.map((r, i) => (
            <DropdownItem
              key={i}
              title={(r.isCountryEntry ? r.country : r.city) as string}
              onSelect={() => searchTermSelected(r)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const DropdownItem = ({
  title,
  value,
  onSelect,
}: {
  title: string;
  value?: string;
  onSelect: (value: string) => void;
}) => {
  return (
    <div
      onClick={() => onSelect(value ?? title)}
      css={css`
        padding: 20px;
        width: 100%;
        :hover {
          background-color: ${colors.background};
        }
      `}
    >
      <Text variant="Body">{title}</Text>
    </div>
  );
};

export default SearchSelect;
