import { css } from '@emotion/react';
import React from 'react';
import { Activity, Supplier, Venue } from 'schemaTypes';
import Carusel from './Carusel';
import CardTile from './CardTile';

type CustomCardViewProps = {
  featuredCardsLists: {
    featuredCards: {
      title: string;
      cards: (Venue | Activity | Supplier)[];
    }[];
  };
};
// Feel free to rename this file if you find a better name
const CustomCardView = ({ featuredCardsLists }: CustomCardViewProps) => {
  return (
    <div>
      {featuredCardsLists.featuredCards?.map((featuredCardsList, index) => (
        <div
          key={index}
          css={css`
            margin-top: 40px;
          `}
        >
          <Carusel title={featuredCardsList.title}>
            {featuredCardsList.cards?.map((card, index) => (
              <CardTile
                key={index}
                image={card.generalInfo?.mainImage}
                type={card._type}
                title={card.generalInfo?.title}
                opApproved={card.internalInfo?.opApproved || false}
                sustainable={card.internalInfo?.sustainable || false}
                slug={card.generalInfo?.slug?.current}
                location={card.generalInfo?.address?.city}
              />
            ))}
          </Carusel>
        </div>
      ))}
    </div>
  );
};

export default CustomCardView;
