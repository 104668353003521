import { css } from '@emotion/react';
import React, { useRef, useState } from 'react';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import Row from '../layout/Row';
import { TABLET } from '../theme/breakpoints';
import colors from '../theme/colors';
import { DropdownItem } from './forms/SearchSelect';
import Icon from './icon/Icon';
import { Text } from './Text';
export type SortSettingType = { sortOn: string; ascending: boolean };
type SortDropdownProps = {
  sortChanged: (sortSetting: SortSettingType) => void;
  relevance?: boolean;
  lastUpdated?: boolean;
  lastAdded?: boolean;
  alphabetically?: boolean;
};
const SORT_MAPPING = {
  relevance: 'Relevance',
  lastUpdated: 'Last updated',
  lastAdded: 'Last added',
  alphabetically: 'Alphabetically',
};
const SortDropdown = ({
  sortChanged,
  relevance = true,
  lastUpdated = true,
  lastAdded = true,
  alphabetically = true,
}: SortDropdownProps) => {
  const [sortSelected, setSortSelected] = useState('relevance');
  const [ascending, setAscending] = useState(false);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setShowSortOptions(false);
  });
  const sortOptionClicked = (sortOn: string) => {
    setSortSelected(sortOn);
    const newAscending = sortOn === sortSelected ? !ascending : true;
    setAscending(newAscending);
    sortChanged({ sortOn, ascending: newAscending });
  };
  return (
    <div
      ref={ref}
      onClick={() => setShowSortOptions(!showSortOptions)}
      css={css`
        position: relative;
        cursor: pointer;
      `}
    >
      <Row gap={6}>
        <Icon
          icon="Sort"
          css={css`
            border: 1px solid ${colors.type};
            padding: 1px;
            @media (min-width: ${TABLET}px) {
              border: none;
              padding: 0;
            }
          `}
        />
        <Text
          variant="Body"
          css={css`
            display: none;
            @media (min-width: ${TABLET}px) {
              display: initial;
            }
          `}
        >
          Sorted by: {SORT_MAPPING[sortSelected as keyof typeof SORT_MAPPING]}
        </Text>
      </Row>
      {showSortOptions && (
        <div
          css={css`
            position: absolute;
            background-color: white;
            border: 1px solid ${colors.lightGray};
            right: 0;
          `}
        >
          {relevance && (
            <DropdownItem title="Relevance" value="relevance" onSelect={sortOptionClicked} />
          )}
          {lastUpdated && (
            <DropdownItem title="Last updated" value="lastUpdated" onSelect={sortOptionClicked} />
          )}
          {lastAdded && (
            <DropdownItem title="Last added" value="lastAdded" onSelect={sortOptionClicked} />
          )}
          {alphabetically && (
            <DropdownItem
              title="Alphabetically"
              value="alphabetically"
              onSelect={sortOptionClicked}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SortDropdown;
