import { css } from '@emotion/react';
import React, { useState } from 'react';
import colors from '../theme/colors';
import { Text } from './Text';
type HoverTooltipProps = {
  text: string;
  children: React.ReactNode;
};

const HoverTooltip = ({ text, children }: HoverTooltipProps) => {
  let timeout: any;
  const [tipVisible, setTipVisible] = useState(false);
  const showTip = () => {
    timeout = setTimeout(() => {
      setTipVisible(true);
    }, 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setTipVisible(false);
  };
  return (
    <div
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      css={css`
        display: inline-block;
        position: relative;
      `}
    >
      {children}
      {tipVisible && (
        <div
          css={css`
            position: absolute;
            padding: 6px;
            color: white;
            background: ${colors.darkGray};
            font-size: 14px;
            font-family: sans-serif;
            line-height: 1;
            z-index: 100;
            white-space: nowrap;
            top: 0;
            left: 50%;
            left: calc(100% + 10px);
            top: 50%;
            transform: translateX(0) translateY(-50%);
            &::before {
              content: ' ';
              left: 50%;
              border: solid transparent;
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-width: 6px;
              margin-left: calc(6px * -1);
              /* background: ${colors.darkGray}; */

              left: calc(6px * -1);
              top: 50%;
              transform: translateX(0) translateY(-50%);
              border-right-color: ${colors.darkGray};
            }
          `}
        >
          <Text variant="Details">{text}</Text>
        </div>
      )}
    </div>
  );
};

export default HoverTooltip;
